import React, { useState } from 'react';

const DocumentUpload = ({ documents, setDocuments, legalEntityId, onClose }) => {
  const [documentType, setDocumentType] = useState('');
  const [year, setYear] = useState('');
  const [documentFile, setDocumentFile] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleFileChange = (e) => {
    setDocumentFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!documentType || !year || !documentFile) {
        alert("Completa todos los campos");
        return;
    }

    const formData = new FormData();
    formData.append('document_type', documentType);
    formData.append('year', year);
    formData.append('document', documentFile);
    formData.append('entity_id', legalEntityId);

    try {
        const response = await fetch('https://api.finanbid.com/api/upload-entity-document', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Error en la carga del documento');
        }

        const newDocument = await response.json();

        // Asegúrate de agregar el nuevo documento al array existente
        setDocuments((prevDocuments) => [...prevDocuments, newDocument]);

        // Muestra el popup de confirmación y cierra el modal
        alert("Documento subido correctamente");
        onClose(); // Cierra el modal de subida de documentos
    } catch (error) {
        console.error("Error al subir el documento:", error);
    }
};

  const handleCloseConfirmation = () => {
    setShowConfirmation(false); // Cerrar el popup
    onClose(); // Cerrar el modal principal
  };

  return (
    <div className="mt-6">
      <h4 className="text-lg font-semibold">Subir Documentos</h4>
      <div className="flex space-x-2 mt-3">
        <input 
          type="text" 
          value={documentType} 
          onChange={(e) => setDocumentType(e.target.value)} 
          placeholder="Tipo de Documento" 
          className="p-2 border rounded w-1/3" 
        />
        <input 
          type="number" 
          value={year} 
          onChange={(e) => setYear(e.target.value)} 
          placeholder="Año" 
          className="p-2 border rounded w-1/4" 
        />
        <input 
          type="file" 
          onChange={handleFileChange} 
          className="p-2 w-1/3" 
        />
        <button 
          onClick={handleUpload} 
          className="bg-blue-500 text-white px-4 rounded"
        >
          Subir
        </button>
      </div>
      <div className="mt-4 space-y-2">
        {documents.map((doc, index) => (
          <div key={index} className="flex justify-between items-center text-sm">
            <span>{doc.document_type} ({doc.year})</span>
            <a href={doc.document_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">Ver</a>
          </div>
        ))}
      </div>

      {/* Popup de confirmación */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Documento Subido</h3>
            <p className="text-gray-600">El documento se ha subido correctamente.</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleCloseConfirmation}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUpload;
