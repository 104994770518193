import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const UserDetails = ({ onBack }) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [collaboratorCode, setCollaboratorCode] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const userResponse = await axios.get(`https://api.finanbid.com/api/user/profileadmin/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const projectsResponse = await axios.get(
          `https://api.finanbid.com/api/projects/user/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setUserDetails(userResponse.data);
        setProjects(projectsResponse.data);

        if (userResponse.data.role === 'colaborador') {
          const collaboratorResponse = await axios.get(
            `https://api.finanbid.com/api/collaborators/${userId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setCollaboratorCode(collaboratorResponse.data.code);
        }
      } catch (err) {
        console.error(err);
        setError(t('adminUsers.errorFetchingData'));
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId, t]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    try {
      const token = localStorage.getItem('token');
      await axios.patch('https://api.finanbid.com/api/user/userprofile', userDetails, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert(t('admin.Users.profileUpdated'));
    } catch (err) {
      console.error(err);
      alert(t('admin.Users.errorUpdatingProfile'));
    } finally {
      setSaving(false);
    }
  };

  const handleRoleChange = async (newRole) => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(
        `https://api.finanbid.com/api/user/role/${userId}`,
        { role: newRole },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (newRole === 'colaborador') {
        await convertToCollaborator();
      } else {
        setCollaboratorCode(null); // Limpiar código de colaborador si el rol cambia
      }

      setUserDetails((prev) => ({ ...prev, role: newRole }));
    } catch (err) {
      console.error(err);
      alert(t('admin.Users.errorUpdatingRole'));
    }
  };

  const generateUniqueCollaboratorCode = async () => {
    let code;
    const token = localStorage.getItem('token');
    let isUnique = false;

    while (!isUnique) {
      code = Math.floor(100000 + Math.random() * 900000).toString();
      const response = await axios.get(
        `https://api.finanbid.com/api/collaborators/check-code/${code}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      isUnique = response.data.isUnique;
    }

    return code;
  };

  const convertToCollaborator = async () => {
    const confirm = window.confirm(t('admin.Users.confirmConvertToCollaborator'));
    if (!confirm) return;

    try {
      const token = localStorage.getItem('token');

      // Verificar si ya existe un colaborador
      let existingCollaborator;
      try {
        const response = await axios.get(
          `https://api.finanbid.com/api/collaborators/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        existingCollaborator = response.data;
      } catch (err) {
        if (err.response?.status !== 404) {
          console.error('Error fetching collaborator:', err);
          alert(t('admin.Users.errorFetchingCollaborator'));
          return;
        }
        // Si el error es 404, continuamos con la creación
      }

      if (existingCollaborator?.code) {
        alert(t('admin.Users.alreadyCollaborator'));
        setCollaboratorCode(existingCollaborator.code);
        setUserDetails((prev) => ({ ...prev, role: 'colaborador' }));
        return;
      }

      // Generar un nuevo código único
      const collaboratorCode = await generateUniqueCollaboratorCode();

      // Insertar en la tabla de colaboradores
      await axios.post(
        'https://api.finanbid.com/api/collaborators',
        { user_id: userId, code: collaboratorCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Actualizar el rol del usuario a 'colaborador'
      await axios.patch(
        `https://api.finanbid.com/api/user/role/${userId}`,
        { role: 'colaborador' },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      alert(t('admin.Users.collaboratorCreated'));
      setCollaboratorCode(collaboratorCode);
      setUserDetails((prev) => ({ ...prev, role: 'colaborador' }));
    } catch (err) {
      console.error('Error creating collaborator:', err);
      alert(t('admin.Users.errorCreatingCollaborator'));
    }
  };

  const deleteUser = async () => {
    const confirm = window.confirm(t('admin.Users.confirmDelete'));
    if (!confirm) return;

    try {
      const token = localStorage.getItem('token');

      const response = await fetch(`https://api.finanbid.com/api/user/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      alert(t('admin.Users.userDeleted'));
      navigate('/admin/users');
    } catch (err) {
      console.error('Error deleting user:', err);
      alert(t('admin.Users.errorDeletingUser'));
    }
  };

  if (loading) {
    return <p>{t('admin.Users.loading')}</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="p-4">
      <button onClick={onBack} className="mb-4 text-blue-500 hover:underline">
        {t('admin.Users.back')}
      </button>

      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">{t('admin.Users.details')}</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block font-semibold mb-2">{t('admin.Users.name')}</label>
            <input
              type="text"
              name="name"
              value={userDetails.name || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block font-semibold mb-2">{t('admin.Users.email')}</label>
            <input
              type="email"
              name="email"
              value={userDetails.email || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block font-semibold mb-2">{t('admin.Users.phone')}</label>
            <input
              type="text"
              name="phone_number"
              value={userDetails.phone_number || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block font-semibold mb-2">{t('admin.Users.role')}</label>
            <select
              value={userDetails.role || ''}
              onChange={(e) => handleRoleChange(e.target.value)}
              className="border p-2 rounded w-full"
            >
              <option value="promotor">{t('admin.Users.roles.promotor')}</option>
              <option value="fondo">{t('admin.Users.roles.fondo')}</option>
              <option value="colaborador">{t('admin.Users.roles.colaborador')}</option>
            </select>
          </div>
          {userDetails.role === 'colaborador' && (
            <div>
              <label className="block font-semibold mb-2">{t('admin.Users.collaboratorCode')}</label>
              <input
                type="text"
                value={collaboratorCode || ''}
                readOnly
                className="border p-2 rounded w-full bg-gray-100"
              />
            </div>
          )}
          <div>
            <label className="block font-semibold mb-2">{t('admin.Users.status')}</label>
            <select
              name="status"
              value={userDetails.status || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full"
            >
              <option value="pending">{t('admin.Users.statusOptions.pending')}</option>
              <option value="active">{t('admin.Users.statusOptions.active')}</option>
              <option value="blocked">{t('admin.Users.statusOptions.blocked')}</option>
            </select>
          </div>
        </div>
        <button
          onClick={handleSaveChanges}
          disabled={saving}
          className="mt-4 px-4 py-2 mx-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
        >
          {saving ? t('admin.Users.saving') : t('admin.Users.saveChanges')}
        </button>
        {userDetails.role !== 'colaborador' && (
          <button
            onClick={convertToCollaborator}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            {t('admin.Users.convertToCollaborator')}
          </button>
        )}
        <button
          onClick={deleteUser}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 float-right"
        >
          {t('admin.Users.deleteUser')}
        </button>
      </div>
    </div>
  );
};

export default UserDetails;
