import React, { useEffect, useState } from 'react';
import { refreshUserToken } from './utils/auth.js';
import { BrowserRouter as Router, Route, Routes, useLocation, Switch } from 'react-router-dom';
import Header from './components/Header';
import CTA from './components/CTA';
import ScrollToTop from './components/ScrollToTop';
import Hero from './components/Hero';
import Bidfunding from './components/Bidfunding';
import Steps from './components/Steps';
import AcercaDe from './components/AcercaDe';
import CallToAction from './components/CallToAction';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Funds from './components/Fondos';
import HeroFunds from './components/HeroFondos';
import Trustlogos from './components/TrustLogos';
import Inmobiliario from './components/Inmobiliario';
import Colaboradores from './components/colaboradores.js';
import Newsletter from './components/Newsletter';
import Renovables from './components/Renovables';
import ComoFunciona from './components/ComoFunciona.js';
import HeroComoFunciona from './components/HeaderComoFunciona.js';
import SubirProyecto from './components/SubirProyecto';
import DashboardAdmin from './components/dashadmin/DashboardAdmin';
import DashboardUser from './components/dashuser/DashboardUser';
import DashboardHome from './components/dashuser/DashboardHome';
import DashboardFondos from './components/dashfondo/DashboardFondos';
import UserProjects from './components/dashuser/UserProjects';
import UserProfile from './components/dashuser/UserProfile';
import UserSettings from './components/dashuser/UserSettings';
import PrestamoPuente from './components/prestamoPuente.js';
import PrestamoPromotor from './components/prestamoPromotor';
import PrestamoFlipping from './components/prestamoFlipping';
import ProtectedRoute from './components/ProtectedRoute'; // Importar el componente de ruta protegida
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import socket from './utils/socket.js'; // Importa el socket centralizado
import 'cookieconsent/build/cookieconsent.min.css';
import 'cookieconsent';
import Privacidad from './components/Privacidad';
import TerminosYCondiciones from './components/termsFollowups';

function App() {
  const location = useLocation();  // Asegúrate de usar esto dentro del contexto de Router
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isDashboardRoute = location.pathname.startsWith('/dashboard');
  const isDashboardFundRoute =
    location.pathname.startsWith('/fund') && !location.pathname.startsWith('/funds');
  const [realTimeData, setRealTimeData] = useState(null);
  const isAuthenticated = !!localStorage.getItem('token');

  useEffect(() => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#113238",
          text: "#fff",
        },
        button: {
          background: "#00d0b2",
          text: "#000",
        },
      },

      content: {
        message: "Utilizamos cookies para asegurar la mejor experiencia en nuestro sitio web.",
        dismiss: "Aceptar",
        link: "Saber más",
        href: "/politica-de-cookies", // Cambia esto a la URL de tu política de cookies
      },
      position: "bottom-right", // Opciones: 'bottom', 'top', 'bottom-left', 'bottom-right'
    });
  }, []);


  // Token Refresh Logic
  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          await refreshUserToken();
        } catch (error) {
          console.error('Error al refrescar el token:', error);
        }
      }
    };

    // Llamar a la función al cargar la app
    checkAndRefreshToken();

    // Configurar un intervalo para renovar el token periódicamente (cada 15 minutos, por ejemplo)
    const intervalId = setInterval(() => {
      checkAndRefreshToken();
    }, 15 * 60 * 1000); // 15 minutos

    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar
  }, []);
  // useEffect para manejar WebSocket
  useEffect(() => {

    // Conexión al WebSocket
    socket.on('connect', () => {
      console.log('WebSocket conectado:', socket.id);
    });

    // Manejo de eventos de WebSocket
    socket.on('newMessage', (message) => {
      console.log('Nuevo mensaje recibido:', message);
      // Aquí puedes manejar el evento de nuevo mensaje en toda la app
      // Ejemplo: mostrar notificaciones o actualizar algún estado global
    });

    // Limpieza: solo elimina los listeners, pero no desconecta el socket
    return () => {
      socket.off('connect');
      socket.off('newMessage');
    };
  }, []); // Ejecuta solo una vez

  return (
    <div className="App bg-back min-h-screen">
      {!isAdminRoute && !isDashboardRoute && !isDashboardFundRoute && <Header />}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
  {/* Meta Tags Básicos */}
  <title>Finanbid - Plataforma de Financiación para Promotores Inmobiliarios y empresarios mediante bidfunding</title>
  <link rel="canonical" href="https://www.finanbid.com" />
  <link rel="shortcut icon" href="https://www.finanbid.com/favicon.png" type="image/x-icon" />
  <meta
    name="description"
    content="Finanbid permite a promotores inmobiliarios recibir pujas de fondos de inversión, ofreciendo condiciones competitivas en un entorno confidencial y digital."
  />
  <meta name="keywords" content="financiación alternativa, préstamos para promotores, prestamo puente, prestamo promotor, compra activo, bidfunding, capital privado, flipping inmobiliario, real estate" />
  <meta name="robots" content="index, follow" />
  <meta name="author" content="Finanbid" />
  <meta name="language" content="es" />
  <meta name="geo.region" content="ES" />
  <meta name="geo.placename" content="España" />

  {/* OpenGraph y Twitter */}
  <meta property="og:title" content="Finanbid - Plataforma de Financiación mediante Bidfunding para Promotores Inmobiliarios" />
  <meta property="og:description" content="Conecta con fondos de inversión y obtén condiciones competitivas para tus proyectos inmobiliarios mediante bidfunding." />
  <meta property="og:url" content="https://www.finanbid.com/" />
  <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />
  <meta property="og:type" content="website" />
  <meta property="og:locale" content="es_ES" />
  <meta property="og:locale:alternate" content="en_US" />
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:title" content="Finanbid - Plataforma de Bidfunding para Promotores Inmobiliarios" />
  <meta property="twitter:description" content="Recibe pujas de fondos de inversión para tus proyectos inmobiliarios y accede a financiación privada." />
  <meta property="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

  {/* Schema.org JSON-LD */}
  <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org",
      "@type": "FinancialService",
      "name": "Finanbid",
      "url": "https://www.finanbid.com",
      "description": "Plataforma innovadora de financiación para promotores inmobiliarios mediante bidfunding, donde los fondos compiten por financiar proyectos.",
      "logo": "https://www.finanbid.com/logofinanbidnegro.png",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Av de Sor Teresa Prat, 15",
        "addressLocality": "Málaga",
        "postalCode": "29003",
        "addressCountry": "ES"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+34 614224935",
        "contactType": "Customer Service",
        "email": "hola@finanbid.com",
        "availableLanguage": ["Spanish", "English"]
      },
      "sameAs": [
        "https://www.linkedin.com/company/finanbid",
        "https://twitter.com/finanbid"
      ],
      "areaServed": "ES",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.finanbid.com/search?query={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
    `}
  </script>
</Helmet>

              <Hero />
              <Bidfunding />
              <Steps />
              <Newsletter />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <Helmet>
                <title>Acerca de Finanbid - Innovación en Financiación</title>
                <meta
                  name="description"
                  content="Descubre cómo Finanbid revoluciona la financiación inmobiliaria mediante bidfunding, conectando promotores con fondos."
                />
                <meta name="keywords" content="bidfunding, financiación inmobiliaria, innovación, fondos de inversión, financiación alternativa, prestamo promotor, prestamo puente, financiación privada" />
              </Helmet>
              <AcercaDe />
            </>
          }
        />
        <Route
          path="/comofunciona"
          element={
            <>
              <Helmet>
                <title>¿Cómo Funciona Finanbid? - Bidfunding para Promotores</title>
                <meta
                  name="description"
                  content="Descubre cómo Finanbid conecta promotores inmobiliarios con fondos de inversión mediante un sistema de bidfunding, garantizando las mejores condiciones."
                />
                <meta name="keywords" content="cómo funciona Finanbid, bidfunding, financiación alternativa, préstamos inmobiliarios" />
                <script type="application/ld+json">
                  {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "¿Qué es Finanbid?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Finanbid es la primera plataforma de bidfunding que conecta promotores inmobiliarios con fondos de inversión, facilitando la obtención de condiciones competitivas."
                }
              },
              {
                "@type": "Question",
                "name": "¿Cómo funciona el bidfunding en Finanbid?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Los promotores suben su proyecto, y los fondos de inversión pujan entre sí para ofrecer las mejores condiciones, garantizando rapidez y competitividad."
                }
              },
              {
                "@type": "Question",
                "name": "¿Qué beneficios tiene el sistema de bidfunding?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Los promotores obtienen mejores condiciones, el proceso es completamente digital y confidencial, y los fondos pueden captar más operaciones de forma eficiente."
                }
              }
            ]
          }
          `}
                </script>
              </Helmet>
              <HeroComoFunciona />
              <ComoFunciona />
              <CallToAction />
            </>
          }
        />
        <Route path="/contact" element={
          <>
            <Contacto />
            <CTA />
          </>
        } />
        <Route path="/privacidad" element={<Privacidad />} />
        <Route path="/terms-followups" element={<TerminosYCondiciones />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/funds"
          element={
            <>
              <Helmet>
                <title>Fondos e Inversores en Finanbid</title>
                <meta
                  name="description"
                  content="Accede a proyectos con información verificada y competitiva, conectando fondos con oportunidades únicas de inversión."
                />
                <meta name="keywords" content="fondos de inversión, family offices, financiación privada" />
              </Helmet>
              <HeroFunds />
              <Funds />
              <Newsletter />
            </>
          }
        />
        <Route
          path="/inmobiliario"
          element={
            <>
              <Helmet>
                <title>Financiación Inmobiliaria. Aqui los fondos pujan por tu proyecto - Finanbid</title>
                <meta
                  name="description"
                  content="Desde la compra de suelo hasta la construcción y desarrollo, En Finanbid los fondos pujan por ofrecerte las mejores condiciones de financiación."
                />
                <meta name="keywords" content="financiación inmobiliaria, compra de suelo, préstamos para promotores, real estate, financiacion alternativa, prestamo puente, flipping, promotor inmobiliario, prestamo promotor" />
              </Helmet>
              <Inmobiliario />
            </>
          }
        />

        <Route
          path="/prestamopuente"
          element={
            <>
              <Helmet>
                <title>Préstamo Puente - En Finanbid los fondos pujan por financiar tu proyecto</title>
                <meta
                  name="description"
                  content="Obtén financiación inmediata para cubrir necesidades temporales con nuestro préstamo puente."
                />
                <meta name="keywords" content="préstamo puente, financiación rápida, capital privado" />
              </Helmet>
              <PrestamoPuente />
            </>
          }
        />
        <Route
          path="/prestamopromotor"
          element={
            <>
              <Helmet>
                <title>Préstamo Promotor - Impulsa tu Proyecto Inmobiliario</title>
                <meta
                  name="description"
                  content="Obtén financiación para tus proyectos inmobiliarios con condiciones adaptadas a cada etapa de desarrollo. Finanbid es tu solución de confianza."
                />
                <meta name="keywords" content="préstamo promotor, financiación inmobiliaria, préstamos para construcción, capital privado" />
              </Helmet>
              <PrestamoPromotor />
            </>
          }
        />

        <Route
          path="/prestamoflipping"
          element={
            <>
              <Helmet>
                <title>Préstamo Flipping - Aqui los fondos pujan por financiar tu proyecto</title>
                <meta
                  name="description"
                  content="Financiación ágil y eficiente para proyectos de flipping inmobiliario. Ideal para compra, renovación y reventa de propiedades."
                />
                <meta name="keywords" content="préstamo flipping, inversión inmobiliaria, renovación de propiedades, financiación rápida, financiacion flipping, house flipping, flipping inmobiliario, Fix and Flip" />
              </Helmet>
              <PrestamoFlipping />
            </>
          }
        />

        <Route
          path="/renovables"
          element={
            <>
              <Helmet>
                <title>Financiación para Energías Renovables - Finanbid</title>
                <meta
                  name="description"
                  content="Apoyamos proyectos sostenibles mediante financiación para energías renovables como solar, eólica y bonos verdes."
                />
                <meta name="keywords" content="financiación renovable, energías limpias, bonos verdes, sostenibilidad, financiación renovables, financiacion renovables privada" />
              </Helmet>
              <Renovables />
            </>
          }
        />
        <Route
          path="/colaboradores"
          element={
            <>
              <Helmet>
                <title>Unete a nuestra red de colaboradores - Finanbid</title>
                <meta
                  name="description"
                  content="Unete a nuestra red de colabradores y consigue REALMENTE la mejor financiación para tus clientes"
                />
                <meta name="keywords" content="financiación renovable, energías limpias, bonos verdes, sostenibilidad, financiación renovables, financiacion renovables privada" />
              </Helmet>
              <Colaboradores />
            </>
          }
        />

        <Route path="/subir-proyecto" element={<SubirProyecto />} />
        <Route path="/admin/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}> <DashboardAdmin /></ProtectedRoute>} />
        <Route
          path="/fund/dashboard/*"
          element={
            <ProtectedRoute allowedRoles={['fondo', 'admin']}>
              <DashboardFondos />
            </ProtectedRoute>
          }
        />

        {/* Ruta protegida para el Dashboard del Usuario */}
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <DashboardUser />
            </ProtectedRoute>
          }

        >


          <Route index element={<DashboardHome />} />
          <Route path="projects" element={<ProtectedRoute isAuthenticated={isAuthenticated}><UserProjects /></ProtectedRoute>} />
          <Route path="profile" element={<ProtectedRoute isAuthenticated={isAuthenticated}><UserProfile /></ProtectedRoute>} />
          <Route path="settings" element={<ProtectedRoute isAuthenticated={isAuthenticated}><UserSettings /></ProtectedRoute>} />
        </Route>

      </Routes>

      {!isAdminRoute && !isDashboardRoute && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default AppWrapper;