import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faUsers, faFolder, faChartLine, faMoneyBill, faCog, faEnvelope, faHandshake } from '@fortawesome/free-solid-svg-icons';
import AdminHome from './AdminHome';
import Users from './Users';
import UserDetails from './UserDetails';
import AdminProjects from './AdminProjects';
//import AdminProject from './AdminProject';
import AdminProject from './ProjectNew';
import Reports from './Reports';
import Funds from './Funds';
import FundDetails from './FundDetails';
import Settings from './Settings';
import AdminOffers from './AdminOffers';
import AdminInbox from './Inbox';
import DueDiligenceEdit from './DueDiligenceEdit';

const DashboardAdmin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [inboxCount, setInboxCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [funds, setFunds] = useState([]); // Estado para almacenar los fondos

  // Obtener cantidad de mensajes no leídos
  useEffect(() => {
    const fetchInboxCount = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        const role = localStorage.getItem('role');

        if (!userId || !role) {
          console.error('Faltan userId o role en el localStorage.');
          return;
        }

        const response = await fetch('https://api.finanbid.com/api/messages/unread-count', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            role: role,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error al obtener la cantidad de mensajes: ${response.status}`);
        }

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    fetchInboxCount();
  }, []);

  // Obtener lista de fondos
  useEffect(() => {
    const fetchFunds = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/funds/list');
        const data = await response.json();
        setFunds(data);
      } catch (error) {
        console.error('Error al obtener fondos:', error);
      }
    };

    fetchFunds();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      {/* Header */}
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 md:hidden">
        <img className="h-8 w-auto" src="/logofinanbid.png" alt="Admin Dashboard" />
        <button onClick={toggleSidebar} className="text-white focus:outline-none">
          <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="lg" />
        </button>
      </header>

      {/* Sidebar */}
      <nav
        className={`bg-gray-800 w-64 h-full flex flex-col justify-between fixed top-0 md:relative z-10 transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 transition-transform duration-200 ease-in-out`}
      >
        <div>
          <div className="h-16 bg-gray-900 flex items-center justify-center">
            <img className="h-12 w-auto hidden md:block" src="/logofinanbid.png" alt="Admin Dashboard" />
          </div>
          <div className="flex-1 mt-8 space-y-2">
            <Link to="/admin" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faHome} className="mr-2" /> {t('dashboard.home')}
            </Link>
            <Link to="/admin/users" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faUsers} className="mr-2" /> {t('dashboardAdmin.users')}
            </Link>
            <Link to="/admin/projects" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faFolder} className="mr-2" /> {t('dashboardAdmin.projects')}
            </Link>
            <Link to="/admin/offers" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faHandshake} className="mr-2" /> {t('dashboardAdmin.offers')}
            </Link>
            <Link to="/admin/reports" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faChartLine} className="mr-2" /> {t('dashboardAdmin.reports')}
            </Link>
            <Link to="/admin/funds" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faMoneyBill} className="mr-2" /> {t('dashboardAdmin.funds')}
            </Link>
            <Link to="/admin/settings" className="block text-gray-400 hover:text-white px-4 py-2 flex items-center" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faCog} className="mr-2" /> {t('dashboardAdmin.settings')}
            </Link>
            <Link
              to="/admin/inbox"
              className={`block px-4 py-2 flex items-center ${inboxCount > 0 ? 'text-secondary font-bold' : 'text-gray-400 hover:text-white'}`}
              onClick={() => setIsSidebarOpen(false)}
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> {inboxCount > 0 ? `${t('dashboard.inbox')} (${inboxCount})` : t('dashboard.inbox')}
            </Link>
          </div>
        </div>
        <div className="mb-4 space-y-2">
          <button onClick={handleLogout} className="block text-gray-400 hover:text-white px-4 py-2 w-full text-left">
            {t('dashboard.logout')}
          </button>
        </div>
      </nav>

      {/* Contenido Principal */}
      <main className="flex-1 h-screen overflow-y-auto p-6 bg-gray-100">
        <Routes>
          <Route path="/" element={<AdminHome />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<UserDetails />} />
          <Route path="/projects" element={<AdminProjects />} />
          <Route path="/offers" element={<AdminOffers />} />
          <Route path="/projects/:projectId" element={<AdminProject />} />
          <Route path="/funds" element={<Funds funds={funds} />} /> {/* Pasamos los fondos */}
          <Route path="/funds/:fundId" element={<FundDetails />} /> {/* Nueva ruta */}
          <Route path="/settings" element={<Settings />} />
          <Route path="/inbox" element={<AdminInbox />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/:projectId/initial-dd" element={<DueDiligenceEdit />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardAdmin;




