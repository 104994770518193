import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const UserOffers = () => {
  const { t } = useTranslation();

  const [projects, setProjects] = useState([]);
  const [referredProjects, setReferredProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [offers, setOffers] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [activeTab, setActiveTab] = useState("own");

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  // Fetch Projects
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const role = localStorage.getItem("role");
        setUserRole(role);

        const response = await axios.get("https://api.finanbid.com/api/projects", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const updatedProjects = await updateProjectsWithOffers(response.data);
        setProjects(updatedProjects);

        if (role === "colaborador") {
          const referredResponse = await axios.get(
            `https://api.finanbid.com/api/projects/referred/${userId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const updatedReferredProjects = await updateProjectsWithOffers(referredResponse.data);
          setReferredProjects(updatedReferredProjects);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [token, userId]);

  // Update each project with offers_count and unread status
  const updateProjectsWithOffers = async (projects) => {
    const updatedProjects = await Promise.all(
      projects.map(async (project) => {
        try {
          const offersResponse = await axios.get(
            `https://api.finanbid.com/api/offers?project_id=${project.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const offers = offersResponse.data;

          const unreadOffers = offers.some((offer) => !offer.is_read);
          return { ...project, offers_count: offers.length, has_unread_offers: unreadOffers };
        } catch (error) {
          console.error(`Error fetching offers for project ${project.id}:`, error);
          return { ...project, offers_count: 0, has_unread_offers: false };
        }
      })
    );

    return updatedProjects;
  };

  // Fetch Offers
  const fetchOffers = async (projectId) => {
    try {
      const response = await axios.get(`https://api.finanbid.com/api/offers?project_id=${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOffers(response.data);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const handleProjectSelect = (projectId) => {
    setSelectedProjectId(projectId);
    setSelectedOfferId(null);
    fetchOffers(projectId);
  };

  const handleOfferSelect = async (offerId) => {
    setSelectedOfferId(offerId);

    try {
      await axios.patch(
        `https://api.finanbid.com/api/user/offers/${offerId}/field`,
        { field: "is_read", value: true },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOffers((prevOffers) =>
        prevOffers.map((offer) => (offer.id === offerId ? { ...offer, is_read: true } : offer))
      );
    } catch (error) {
      console.error("Error updating is_read:", error);
    }
  };

  const renderProjects = (projects) => (
    <div className="w-1/3 border-r overflow-y-auto bg-white">
      <h3 className="text-lg font-bold p-4">{t("userOffers.projects")}</h3>
      <ul>
        {projects.map((project) => {
          const hasUnreadOffers = project.offers_count > 0 && project.has_unread_offers;
          return (
            <li
              key={project.id}
              className={`p-4 border-b cursor-pointer flex justify-between items-center text-sm ${
                selectedProjectId === project.id ? "bg-blue-100" : "hover:bg-gray-100"
              } ${hasUnreadOffers ? "bg-primarysuave" : ""}`}
              onClick={() => handleProjectSelect(project.id)}
            >
              <div>
                <h4 className="text-md font-semibold">
                  {t(`projectUpload.products.${project.product_type}`)} ({project.localidad})
                </h4>
                <p className="text-gray-500">
                  {t("userOffers.offersCount")}: {project.offers_count}
                </p>
              </div>
              {hasUnreadOffers && (
                <span className="text-sm text-red-500 font-bold">{t("userOffers.unread")}</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );

  const renderOffers = () => (
    <div className="w-2/3 p-4 bg-white">
      <h3 className="text-sm font-bold mb-4">{t("userOffers.offers")}</h3>
      {offers.length > 0 ? (
        <div>
          {/* Encabezados */}
          <div className="grid grid-cols-4 gap-4 text-sm font-bold bg-gray-200 p-2 rounded">
            <span>{t("offers.headers.interestRate")}</span>
            <span>{t("offers.headers.openingFee")}</span>
            <span>{t("offers.headers.offerDate")}</span>
            <span>{t("offers.headers.details")}</span>
          </div>
  
          {/* Lista de ofertas */}
          {offers.map((offer) => {
            // Determinar el estado de la oferta
            let offerStatus;
            if (offer.accepted) {
              offerStatus = t("userOffers.accepted");
            } else if (offer.refused) {
              offerStatus = t("userOffers.refused");
            } else {
              offerStatus = t("userOffers.pending");
            }
  
            return (
              <div
                key={offer.id}
                className={`grid grid-cols-4 gap-4 items-center p-2 border-b cursor-pointer text-sm ${
                  selectedOfferId === offer.id ? "bg-blue-50" : "hover:bg-gray-50"
                }`}
                onClick={() => handleOfferSelect(offer.id)}
              >
                <span>{offer.interest_rate_monthly}%</span>
                <span>{offer.opening_fee}%</span>
                <span>{new Date(offer.offer_date).toLocaleDateString()}</span>
                <span
                  className={`font-semibold ${
                    offer.is_read ? "text-gray-500" : "text-blue-500"
                  }`}
                >
                  {offer.is_read ? offerStatus : t("userOffers.unread")}
                </span>
              </div>
            );
          })}
  
          {/* Detalle de la oferta seleccionada */}
          {selectedOfferId && renderOfferDetails()}
        </div>
      ) : (
        <p className="text-gray-500 mt-4">{t("userOffers.noOffers")}</p>
      )}
    </div>
  );
  

  const renderOfferDetails = () => {
    const offer = offers.find((o) => o.id === selectedOfferId);
    return (
        <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-lg text-sm">
            <h4 className="text-sm font-bold mb-4">{t("offers.details")}</h4>
            <div className="grid grid-cols-2 gap-4">
                <p>
                    <strong>{t("offers.interestRate")}:</strong> {offer.interest_rate_monthly}%
                </p>
                <p>
                    <strong>{t("offers.openingFee")}:</strong> {offer.opening_fee}%
                </p>
                <p>
                    <strong>{t("offers.term")}:</strong> {offer.term_months} {t("common.months")}
                </p>
                <p>
                    <strong>{t("offers.description")}:</strong> {offer.offer_description || "N/A"}
                </p>
            </div>

            {/* Documentos de la oferta */}
            {offer.offer_document_url && (
                <div className="mt-4">
                    <h5 className="font-semibold mb-2">{t("offers.documents")}</h5>
                    <a
                        href={offer.offer_document_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                    >
                        {t("offers.viewDocument")}
                    </a>
                </div>
            )}

            {/* Botones de acción */}
            <div className="flex space-x-4 mt-6">
                <button
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    onClick={() => handleAcceptOffer(offer.id)}
                >
                    {t("buttons.acceptOffer")}
                </button>
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    onClick={() => handleRejectOffer(offer.id)}
                >
                    {t("buttons.rejectOffer")}
                </button>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => handleContactFund(offer.fund_id)}
                >
                    {t("buttons.contactFund")}
                </button>
            </div>
        </div>
    );
};

const handleAcceptOffer = async (offerId) => {
    try {
        const confirmAccept = window.confirm(t("offers.acceptConfirmation"));
        if (!confirmAccept) return;

        await axios.patch(
            `https://api.finanbid.com/api/user/offers/${offerId}/field`,
            { field: "accepted", value: true },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        alert(t("offers.acceptSuccess"));
        fetchOffers(selectedProjectId);
    } catch (error) {
        console.error("Error accepting offer:", error);
        alert(t("offers.acceptError"));
    }
};

const handleRejectOffer = async (offerId) => {
  try {
    const confirmReject = window.confirm(t("offers.rejectConfirmation"));
    if (!confirmReject) return;

    // Actualizar el campo "accepted" a false
    await axios.patch(
      `https://api.finanbid.com/api/user/offers/${offerId}/field`,
      { field: "accepted", value: false },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    // Actualizar el campo "refused" a true
    await axios.patch(
      `https://api.finanbid.com/api/user/offers/${offerId}/field`,
      { field: "refused", value: true },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    alert(t("offers.rejectSuccess"));
    fetchOffers(selectedProjectId); // Recargar las ofertas después de la actualización
  } catch (error) {
    console.error("Error rejecting offer:", error);
    alert(t("offers.rejectError"));
  }
};


const handleContactFund = (fundId) => {
    // Aquí podrías redirigir a una conversación o mostrar un modal para contactar al fondo.
    alert(t("offers.contactFundAction"));
};


  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">{t("userOffers.title")}</h1>
      <div className="mb-4">
        <button
          onClick={() => setActiveTab("own")}
          className={`text-bold px-4 py-2 rounded-t ${activeTab === "own" ? "bg-white text-blue-500 text-bold" : "bg-gray-200"}`}
        >
          {t("userOffers.ownProjects")}
        </button>
        {userRole === "colaborador" && (
          <button
            onClick={() => setActiveTab("referred")}
            className={`px-4 py-2 rounded-t ${
              activeTab === "referred" ? "bg-white text-blue-500" : "bg-gray-200"
            }`}
          >
            {t("userOffers.referredProjects")}
          </button>
        )}
      </div>
      <div className="flex border-t">
        {activeTab === "own" && renderProjects(projects)}
        {activeTab === "referred" && userRole === "colaborador" && renderProjects(referredProjects)}
        {selectedProjectId && renderOffers()}
      </div>
    </div>
  );
};

export default UserOffers;

