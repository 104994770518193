import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faFileContract, faRectangleList, faCommentDots, faTrash, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Tracking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [trackings, setTrackings] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [offers, setOffers] = useState([]);
  const [fundId, setFundId] = useState(null);
  const [isImproveModalOpen, setImproveModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [deleteOfferId, setDeleteOfferId] = useState(null); // Para el modal de confirmación
  const [newDocument, setNewDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState(null); // Proyecto seleccionado
  const [selectedFile, setSelectedFile] = useState(null); // Archivo para subir
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    interest_rate_monthly: '',
    opening_fee: '',
    term_months: '',
    interest_repayment: '',
  });
  const fetchFundId = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token no encontrado');
      }

      const response = await axios.get('https://api.finanbid.com/api/fund-users/fund', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setFundId(response.data.fund_id);
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        navigate('/login?redirect=/fund/dashboard/tracking');
      } else {
        console.error('Error al obtener el fundId:', error);
      }
    }
  };
  // Fetch tracking data
  const fetchTrackings = async () => {
    if (!fundId) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token no encontrado');
      }

      const response = await axios.get(`https://api.finanbid.com/api/followups/${fundId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTrackings(response.data);
    } catch (error) {
      console.error('Error al obtener los seguimientos:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFundId();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token no encontrado');
        }

        // Llamada para obtener el fundId
        const fundResponse = await axios.get(`https://api.finanbid.com/api/fund-users/fund`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFundId(fundResponse.data.fund_id);

        // Llamada para obtener los seguimientos
        const followupsResponse = await axios.get(`https://api.finanbid.com/api/followups/${fundResponse.data.fund_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Agrega la comprobación de oferta
        const trackingsWithOffers = await Promise.all(
          followupsResponse.data.map(async (tracking) => {
            const offerResponse = await axios.get(
              `https://api.finanbid.com/api/offers?project_id=${tracking.project_id}&fund_id=${fundResponse.data.fund_id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            return {
              ...tracking,
              user_offer: offerResponse.data.length > 0 ? offerResponse.data[0] : null,
            };
          })
        );

        setTrackings(trackingsWithOffers);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (fundId) {
      fetchTrackings();
    }
  }, [fundId]);
  const fetchOffers = async (projectId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api.finanbid.com/api/offers?project_id=${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOffers(response.data);
    } catch (error) {
      console.error('Error al obtener las ofertas:', error);
    }
  };

  const toggleRow = (projectId) => {
    if (expandedRow === projectId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(projectId);
      fetchOffers(projectId);
    }
  };
  const handleDeleteOffer = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !deleteOfferId) throw new Error('Datos insuficientes para eliminar la oferta');

      await axios.delete(`https://api.finanbid.com/api/offers/${deleteOfferId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Actualizar listado después de eliminar
      fetchTrackings();
      setOffers((prevOffers) => prevOffers.filter((offer) => offer.id !== deleteOfferId));

      setDeleteOfferId(null);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error('Error eliminando la oferta:', error);
    }
  };
  const handleStartConversation = async (projectId) => {
    try {
      setLoading(true);

      const token = localStorage.getItem('token');
      const userId = parseInt(localStorage.getItem('userId'), 10);

      if (!token || !userId) {
        throw new Error('Token o UserId no encontrados.');
      }

      // Obtener el proyecto y el comId
      const projectResponse = await axios.get(`https://api.finanbid.com/api/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const recipientId = projectResponse.data.comId; // Ahora usamos el comId en lugar del user_id

      if (!recipientId) {
        throw new Error('El usuario para las comunicaciones (comId) no fue encontrado.');
      }

      // Comprobar si ya existe una conversación
      const checkConversationResponse = await axios.get(
        `https://api.finanbid.com/api/checkconversations?userId=${userId}&projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { exists, conversation } = checkConversationResponse.data;

      if (exists) {
        // Si ya existe una conversación, redirigir directamente al inbox
        navigate('/fund/dashboard/inbox');
      } else {
        // Crear una nueva conversación
        const conversationResponse = await axios.post(
          'https://api.finanbid.com/api/conversations',
          {
            projectId,
            userId,
            recipientId, // comId como destinatario
            recipientRole: 'promotor', // Esto sigue siendo 'promotor', porque el rol del comId es el relevante aquí
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log('Nueva conversación creada:', conversationResponse.data);
        navigate('/fund/dashboard/inbox');
      }
    } catch (error) {
      console.error('Error al iniciar conversación:', error);
    } finally {
      setLoading(false);
    }
  };






  const openCreateOfferModal = (projectId) => {
    setSelectedProjectId(projectId);
    setFormData({
      interest_rate_monthly: '',
      opening_fee: '',
      term_months: '',
      interest_repayment: '',
      offer_description: '',
    });
    setNewDocument(null);
    setCreateModalOpen(true);
  };
  const closeCreateOfferModal = () => {
    setCreateModalOpen(false);
  };
  const openImproveModal = (offer, projectId) => {
    setSelectedProjectId(projectId);
    setSelectedOffer(offer);
    setFormData({
      id: offer.id, // Asegúrate de incluir el ID
      interest_rate_monthly: offer.interest_rate_monthly,
      opening_fee: offer.opening_fee,
      term_months: offer.term_months,
      interest_repayment: offer.interest_repayment,
      offer_description: offer.offer_description || '',
    });
    setImproveModalOpen(true);
  };

  const handleFileChange = (e) => {
    setNewDocument(e.target.files[0]);
  };
  const handleUpdateOffer = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Token no encontrado');

      // Llamada a la API para actualizar la oferta
      const response = await axios.patch(
        `https://api.finanbid.com/api/offers/${formData.id}`,
        {
          interest_rate_monthly: formData.interest_rate_monthly,
          opening_fee: formData.opening_fee,
          term_months: formData.term_months,
          interest_repayment: formData.interest_repayment,
          offer_description: formData.offer_description || '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) throw new Error('Error al actualizar la oferta');
      const createdOffer = response.data;
      if (selectedFile) {
        const uploadData = new FormData();
        uploadData.append('offer_id', formData.id);
        uploadData.append('document_type', 'offer_document');
        uploadData.append('document', selectedFile);

        const uploadResponse = await axios.post(
          'https://api.finanbid.com/api/upload-offer-document',
          uploadData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!uploadResponse.data) {
          throw new Error('Error al subir el documento de la oferta');
        }
      }
      // Refresca el listado de ofertas
      fetchTrackings(); // Refresca el listado de seguimientos
      fetchOffers(selectedProjectId); // Refresca las ofertas
      setImproveModalOpen(false); // Cierra el modal
    } catch (error) {
      console.error('Error actualizando oferta:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOffer = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Token no encontrado');

      // Llamada a la API para crear la oferta
      const response = await axios.post(
        'https://api.finanbid.com/api/offers',
        {
          project_id: selectedProjectId,
          fund_id: fundId,
          interest_rate_monthly: formData.interest_rate_monthly,
          opening_fee: formData.opening_fee,
          term_months: formData.term_months,
          interest_repayment: formData.interest_repayment,
          offer_description: formData.offer_description || '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || !response.data.offer || !response.data.offer.id) {
        throw new Error('Error al crear la oferta: ID no disponible.');
      }

      const createdOffer = response.data.offer;

      console.log('Oferta creada:', createdOffer);

      // Subir documento si está seleccionado
      if (selectedFile) {
        const uploadData = new FormData();
        uploadData.append('offer_id', createdOffer.id); // Ahora accediendo correctamente al ID
        uploadData.append('document_type', 'offer_document');
        uploadData.append('document', selectedFile);

        console.log('Subiendo documento con datos:', {
          offer_id: createdOffer.id,
          document_type: 'offer_document',
          file: selectedFile.name,
        });

        const uploadResponse = await axios.post(
          'https://api.finanbid.com/api/upload-offer-document',
          uploadData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!uploadResponse.data) {
          throw new Error('Error al subir el documento de la oferta');
        }

        console.log('Documento subido:', uploadResponse.data);
      }

      // Refrescar las listas relevantes
      fetchTrackings(); // Refresca el listado de seguimientos
      fetchOffers(selectedProjectId); // Refresca las ofertas

      // Actualizar el estado de seguimiento con la nueva oferta
      setTrackings((prevTrackings) =>
        prevTrackings.map((tracking) =>
          tracking.project_id === selectedProjectId
            ? { ...tracking, user_offer: createdOffer }
            : tracking
        )
      );

      // Muestra un mensaje de éxito
      alert('Oferta creada con éxito. El usuario ha sido notificado.');
      setCreateModalOpen(false); // Cierra el modal
    } catch (error) {
      console.error('Error creando oferta:', error.message || error);
      alert('Error al crear la oferta. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };






  useEffect(() => {
    fetchTrackings();
  }, []);

  return (
    <div className="p-4 bg-gray-50 min-h-screen">
      <h1 className="text-xl font-semibold mb-4">{t('tracking.title')}</h1>
      <div className="bg-white shadow-md rounded-lg">
        {/* Headers del listado */}
        <div className="grid grid-cols-8 gap-4 bg-gray-100 p-4 font-semibold text-sm text-gray-600">
          <span>{t('tracking.headers.projectId')}</span>
          <span>{t('tracking.headers.productType')}</span>
          <span>{t('tracking.headers.country')}</span>
          <span>{t('tracking.headers.province')}</span>
          <span>{t('tracking.headers.amountRequested')}</span>
          <span>{t('tracking.headers.ltv')}</span>
          <span>{t('tracking.headers.offersReceived')}</span>
          <span>{t('tracking.headers.actions')}</span>
        </div>

        {trackings.map((tracking) => (
          <div key={tracking.id} className="border-b border-gray-200">
            {/* Fila principal */}
            <div className="flex items-center justify-between p-4 hover:bg-gray-50 transition">
              <div className="grid grid-cols-8 gap-4 flex-1 items-center text-sm">
                <span>{tracking.project_id}</span>
                <span>{t(`projectUpload.products.${tracking.product_type}`)}</span>
                <span>{tracking.pais}</span>
                <span>{tracking.provincia}</span>
                <span>€{tracking.amount_requested}</span>
                <span>{tracking.ltv ? `${tracking.ltv}%` : t('tracking.noData')}</span>
                <span>{tracking.offers_received || 0}</span>
                <button
                  className="text-blue-500 hover:text-blue-700 flex justify-center"
                  onClick={() => toggleRow(tracking.project_id)}
                >
                  <FontAwesomeIcon icon={expandedRow === tracking.project_id ? faChevronDown : faChevronRight} />
                </button>
              </div>
            </div>

            {/* Contenedor expandido */}
            {expandedRow === tracking.project_id && (
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <div className="grid grid-cols-6 gap-4">
                  {/* Bloque izquierdo: Botones */}
                  <div className="col-span-1 flex flex-col space-y-4">
                    <button
                      className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                      onClick={() => window.location.href = `/fund/dashboard/project/${tracking.project_id}`}
                    >
                      <FontAwesomeIcon icon={faRectangleList} className="mr-2" />
                      {t('buttons.viewDetails')}
                    </button>
                    <button
                      className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                      onClick={() => tracking.user_offer ? openImproveModal(tracking.user_offer, tracking.project_id) : openCreateOfferModal(tracking.project_id)}
                    >
                      <FontAwesomeIcon icon={faFileContract} className="mr-2" />
                      {tracking.user_offer ? t('buttons.improveOffer') : t('buttons.createOffer')}
                    </button>
                    <button
                      className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                      onClick={() => handleStartConversation(tracking.project_id)}
                    >
                      <FontAwesomeIcon icon={faCommentDots} className="mr-2" />
                      {t('buttons.startConversation')}
                    </button>
                  </div>

                  {/* Bloque derecho: Listado de ofertas */}
                  <div className="col-span-5">
                    {offers.length > 0 ? (
                      <div>
                        {/* Encabezados del listado */}
                        <div className="grid grid-cols-6 gap-4 font-semibold text-sm bg-gray-200 p-2 rounded">
                          <span>{t('offers.headers.id')}</span>
                          <span>{t('offers.headers.interestRate')}</span>
                          <span>{t('offers.headers.openingFee')}</span>
                          <span>{t('offers.headers.term')}</span>
                          <span>{t('offers.headers.accepted')}</span>
                          <span>{t('offers.headers.document')}</span>
                        </div>

                        {/* Listado de ofertas */}
                        {offers.map((offer) => (
                          <div
                            key={offer.id}
                            className={`grid grid-cols-6 gap-4 items-center p-2 border-b text-sm ${offer.fund_id === fundId ? 'bg-green-100 font-semibold' : ''}`}
                          >
                            <span className={offer.fund_id === fundId ? '' : 'blur-sm'}>
                              {offer.fund_id === fundId ? t('offers.yourOffer') : offer.id}
                            </span>
                            <span>{offer.interest_rate_monthly}%</span>
                            <span>{offer.opening_fee}%</span>
                            <span>
                              {offer.term_months} {t('months')}
                            </span>
                            <span>
                              {offer.accepted
                                ? <span className="text-green-500 font-bold">{t('offers.accepted')}</span>
                                : offer.refused
                                  ? <span className="text-red-500 font-bold">{t('offers.denied')}</span>
                                  : <span className="text-yellow-600 font-bold">{t('offers.pending')}</span>}
                            </span>
                            <span className="flex justify-center">
                              {offer.fund_id === fundId ? (
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="text-blue-500 cursor-pointer hover:text-blue-700"
                                  onClick={async () => {
                                    try {
                                      const token = localStorage.getItem('token');
                                      const response = await axios.get(
                                        `https://api.finanbid.com/api/offers/${offer.id}/document`,
                                        {
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                          },
                                        }
                                      );

                                      if (response.data?.document_url) {
                                        window.open(response.data.document_url, '_blank');
                                      } else {
                                        alert(t('offers.noDocument'));
                                      }
                                    } catch (error) {
                                      console.error('Error al obtener el documento de la oferta:', error);
                                      alert(t('offers.documentError'));
                                    }
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="text-gray-400"
                                  title={t('offers.noAccess')}
                                />
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-500 text-sm">{t('offers.noOffers')}</p>
                    )}
                  </div>
                </div>
              </div>
            )}


          </div>
        ))}
      </div>
      {/* Modal de confirmación de eliminación */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">{t('offers.deleteConfirmation')}</h3>
            <p>{t('offers.deleteMessage')}</p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                className="text-gray-600"
                onClick={() => {
                  setDeleteOfferId(null);
                  setDeleteModalOpen(false);
                }}
              >
                {t('buttons.cancel')}
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeleteOffer}
              >
                {t('buttons.confirm')}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal para crear oferta */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <h2 className="text-lg font-semibold mb-4">{t('offers.createOffer')}</h2>

            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.interestRate')}</label>
              <input
                type="number"
                name="interest_rate_monthly"
                value={formData.interest_rate_monthly}
                onChange={(e) => setFormData({ ...formData, interest_rate_monthly: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.openingFee')}</label>
              <input
                type="number"
                name="opening_fee"
                value={formData.opening_fee}
                onChange={(e) => setFormData({ ...formData, opening_fee: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.term')}</label>
              <input
                type="number"
                name="term_months"
                value={formData.term_months}
                onChange={(e) => setFormData({ ...formData, term_months: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.interestRepayment')}</label>
              <input
                type="text"
                name="interest_repayment"
                value={formData.interest_repayment}
                onChange={(e) => setFormData({ ...formData, interest_repayment: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.description')}</label>
              <textarea
                name="offer_description"
                value={formData.offer_description}
                onChange={(e) => setFormData({ ...formData, offer_description: e.target.value })}
                className="w-full p-2 border rounded resize-none"
                rows="4" // Puedes ajustar el número de líneas visibles aquí
                placeholder={t('offers.descriptionPlaceholder')} // Agrega un placeholder traducido si deseas
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.uploadDocument')}</label>
              <input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])} // Asegúrate de que esto esté presente
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button className="text-gray-600" onClick={() => setCreateModalOpen(false)}>
                {t('buttons.cancel')}
              </button>
              <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={handleCreateOffer}>
                {t('buttons.save')}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal para mejorar oferta */}
      {isImproveModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <h2 className="text-lg font-semibold mb-4">{t('offers.improveOffer')}</h2>

            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.interestRate')}</label>
              <input
                type="number"
                name="interest_rate_monthly"
                value={formData.interest_rate_monthly}
                onChange={(e) => setFormData({ ...formData, interest_rate_monthly: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.openingFee')}</label>
              <input
                type="number"
                name="opening_fee"
                value={formData.opening_fee}
                onChange={(e) => setFormData({ ...formData, opening_fee: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.term')}</label>
              <input
                type="number"
                name="term_months"
                value={formData.term_months}
                onChange={(e) => setFormData({ ...formData, term_months: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.interestRepayment')}</label>
              <input
                type="text"
                name="interest_repayment"
                value={formData.interest_repayment}
                onChange={(e) => setFormData({ ...formData, interest_repayment: e.target.value })}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.description')}</label>
              <textarea
                name="offer_description"
                value={formData.offer_description}
                onChange={(e) => setFormData({ ...formData, offer_description: e.target.value })}
                className="w-full p-2 border rounded resize-none"
                rows="4" // Puedes ajustar el número de líneas visibles aquí
                placeholder={t('offers.descriptionPlaceholder')} // Agrega un placeholder traducido si deseas
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">{t('offers.uploadDocument')}</label>
              <input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])} // Asegúrate de que esto esté presente
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button className="text-gray-600" onClick={() => setImproveModalOpen(false)}>
                {t('buttons.cancel')}
              </button>
              <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={handleUpdateOffer}>
                {t('buttons.save')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

};

export default Tracking;
