export const calculateLTV = (amountRequested, capitalInvertido, assets, collaterals) => {
    // Sumar los valores de tasación actuales y finales de los activos
    const totalCurrentValue = assets.reduce(
      (sum, asset) => sum + (parseFloat(asset.valor_tasacion) || 0),
      0
    );
  
    const totalFinalValue = assets.reduce((sum, asset) => {
      const valorTasacionFinal = parseFloat(asset.valor_tasacion_final) || 0;
      const valorEmbargos = parseFloat(asset.valor_embargos) || 0; // Maneja null como 0
      const valorHipotecas = parseFloat(asset.valor_hipotecas) || 0; // Maneja null como 0
    
      // Restar embargos e hipotecas
      const valorNeto = valorTasacionFinal - valorEmbargos - valorHipotecas;
    
      return sum + valorNeto; // Acumular el valor neto
    }, 0);
  
    // Sumar el valor total de las garantías
    const totalCollateralsValue = collaterals.reduce(
      (sum, collateral) => sum + (parseFloat(collateral.valor_total_garantia) || 0),
      0
    );
  
    // Cálculo del LTV actual y final
    const denominatorCurrent = capitalInvertido + totalCurrentValue + totalCollateralsValue;
    const denominatorFinal = capitalInvertido + totalFinalValue + totalCollateralsValue;
  
    return {
      current: denominatorCurrent ? ((amountRequested / denominatorCurrent) * 100).toFixed(2) : 0,
      final: denominatorFinal ? ((amountRequested / denominatorFinal) * 100).toFixed(2) : 0,
    };
  };
  