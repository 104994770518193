import React, { useState } from "react";
import FloatingLabelInput from "./FloatingLabelInput";
import { useTranslation } from "react-i18next";

const ShareholderModal = ({ onClose, onSave, legalEntityId }) => {
    const { t } = useTranslation();
    const [shareholderName, setShareholderName] = useState("");
    const [shareholderRole, setShareholderRole] = useState("");
    const [participation, setParticipation] = useState("");
    const [type, setType] = useState("Persona Física"); // Default value
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const handleSave = async () => {
        if (!shareholderName || !participation || !legalEntityId) {
            alert(t("completeAllFields"));
            return;
        }

        const shareholderData = {
            legal_entity_id: legalEntityId,
            shareholder_name: shareholderName,
            shareholder_role: shareholderRole,
            participation: parseFloat(participation),
            type,
        };

        setLoading(true);

        try {
            const response = await fetch("https://api.finanbid.com/api/entity/add-shareholder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                body: JSON.stringify(shareholderData),
            });

            if (!response.ok) {
                throw new Error(t("errorAddingShareholder"));
            }

            const newShareholder = await response.json();
            onSave(newShareholder); // Actualizar la lista en el componente principal
            onClose(); // Cerrar el modal después de guardar
        } catch (error) {
            console.error("Error al agregar accionista:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-[600]">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg">
                <h3 className="text-lg font-semibold mb-4">{t("addShareholder")}</h3>
                <div className="grid grid-cols-2 gap-4">
                    <FloatingLabelInput
                        label={t("shareholderName")}
                        value={shareholderName}
                        onChange={(e) => setShareholderName(e.target.value)}
                        placeholder={t("shareholderNamePlaceholder")}
                    />
                    <FloatingLabelInput
                        label={t("shareholderRole")}
                        value={shareholderRole}
                        onChange={(e) => setShareholderRole(e.target.value)}
                        placeholder={t("shareholderRolePlaceholder")}
                    />
                    <FloatingLabelInput
                        label={t("participation")}
                        type="number"
                        value={participation}
                        onChange={(e) => setParticipation(e.target.value)}
                        placeholder={t("participationPlaceholder")}
                    />
                    <div>
                        <label className="block text-sm font-medium text-gray-700">{t("type")}</label>
                        <select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            className="w-full mt-1 p-2 border rounded"
                        >
                            <option value="Persona Física">{t("individual")}</option>
                            <option value="Persona Jurídica">{t("legalEntity")}</option>
                        </select>
                    </div>
                </div>
                <div className="flex justify-end mt-6">
                    <button
                        className={`px-4 py-2 rounded ${
                            loading ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500 text-white"
                        }`}
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? t("saving") : t("save")}
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-300 rounded ml-2"
                        onClick={onClose}
                        disabled={loading}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShareholderModal;
