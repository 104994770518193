import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Función para cambiar el idioma y almacenarlo en localStorage
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  // Toggle para el menú hamburguesa
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Toggle para el menú desplegable del usuario
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  // Función para cerrar el menú al navegar a una nueva página
  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  // Efecto para autenticar y obtener los datos del usuario
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Llamada a la API para verificar si el token es válido
      fetch('https://api.finanbid.com/api/user', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            // Si el token no es válido, lo eliminamos
            localStorage.removeItem('token');
            setIsAuthenticated(false);
            setUserName('');
            setUserRole(null);
            throw new Error('Token no válido o ha caducado');
          }
          return response.json();
        })
        .then((data) => {
          setIsAuthenticated(true);
          setUserName(data.name);
          setUserRole(data.role);
        })
        .catch((error) => {
          console.error('Error al autenticar el token:', error);
        });
    } else {
      setIsAuthenticated(false);
      setUserName('');
      setUserRole(null);
    }
  }, []);

  // Listener para detectar cuando el usuario se loguea/desloguea
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      if (token) {
        // Vuelve a hacer la llamada a la API cuando cambia el token
        fetch('https://api.finanbid.com/api/user', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              // Si el token no es válido, lo eliminamos
              localStorage.removeItem('token');
              setIsAuthenticated(false);
              setUserName('');
              setUserRole(null);
              throw new Error('Token no válido o ha caducado');
            }
            return response.json();
          })
          .then((data) => {
            setIsAuthenticated(true);
            setUserName(data.name);
            setUserRole(data.role);
          })
          .catch((error) => {
            console.error('Error al autenticar el token:', error);
          });
      } else {
        setIsAuthenticated(false);
        setUserName('');
        setUserRole(null);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full z-50 flex justify-between items-center p-5 bg-back text-white">
      {/* Logo y link a la página de inicio */}
      <div className="flex items-center space-x-4">
        <a href="/">
          <img src="/solologo.png" alt="Logo Finanbid" className="h-14" />
        </a>
        <a href="/" className="font-bold flex items-center">
          <span className="text-2xl font-bold">{t('header.home')}</span>
          {/* Ocultar el slogan en pantallas menores de 1300px */}
          <span className="hidden lg:inline-block lg:max-w-[900px]:hidden text-lg text-secondary font-light ml-2">
            {t('header.slogan')}
          </span>
        </a>
      </div>

      {/* Navegación principal */}
      <nav className="hidden md:flex space-x-6">
        <a href="/about" className="hover:text-gray-300">{t('header.about')}</a>
        <a href="/comofunciona" className="hover:text-gray-300">{t('header.services')}</a>
        <a href="/funds" className="hover:text-gray-300">{t('header.funds')}</a>
        <a href="/colaboradores" className="hover:text-gray-300">{t('header.colaboradores')}</a>
        <a href="/contact" className="hover:text-gray-300">{t('header.contact')}</a>
      </nav>

      {/* Selector de idioma y autenticación */}
      <div className="hidden md:flex space-x-4 items-center">
        <div className="relative">
          <select
            className="bg-secondary text-white py-1 px-2 rounded-md"
            onChange={(e) => changeLanguage(e.target.value)}
            value={i18n.language}
          >
            <option value="es">🇪🇸 Español</option>
            <option value="en">🇬🇧 English</option>
            <option value="sv">🇸🇪 Svenska</option>
            <option value="de">🇩🇪 Deutsch</option>
            <option value="ar">🇩🇪 Arab</option>
          </select>
        </div>

        {/* Mostrar nombre del usuario si está autenticado */}
        {isAuthenticated ? (
          <div className="relative">
            <button 
              className="hover:text-gray-300" 
              onClick={toggleUserMenu}
            >
              {t('header.hello')}, {userName}
            </button>
            {userMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white text-back rounded-md shadow-lg py-2">
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    if (userRole === 'admin') {
                      navigate('/admin/dashboard');
                    } else if (userRole === 'fondo') {
                      navigate('/fund/dashboard');
                    } else if (userRole === 'promotor') {
                      navigate('/dashboard');
                    } else {
                      alert('No tienes permisos para acceder al Dashboard');
                    }
                    setUserMenuOpen(false);
                  }}
                >
                  {t('header.dashboard')}
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('role');
                    localStorage.removeItem('userId');
                    setIsAuthenticated(false);
                    navigate('/');
                  }}
                >
                  {t('header.logout')}
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <a href="/login" className="hidden sm:inline hover:text-gray-300">{t('header.login')}</a>
           {/*<a href="/login" className="hidden sm:inline bg-primary text-white py-1 px-4 rounded-lg hover:bg-gray-100 transition">
              {t('header.register')}
            </a>*/}
          </>
        )}
      </div>

      {/* Menú hamburguesa responsive */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>

      {/* Menú desplegable en pantallas pequeñas */}
      {isOpen && (
        <div className="absolute top-16 left-0 w-full bg-back text-white flex flex-col items-center space-y-4 p-4">
          <button onClick={() => handleNavigation('/about')} className="hover:text-gray-300 cursor-pointer">
            {t('header.about')}
          </button>
          <button onClick={() => handleNavigation('/comofunciona')} className="hover:text-gray-300 cursor-pointer">
            {t('header.services')}
          </button>
          <button onClick={() => handleNavigation('/funds')} className="hover:text-gray-300 cursor-pointer">
            {t('header.funds')}
          </button>
          <button onClick={() => handleNavigation('/colaboradores')} className="hover:text-gray-300 cursor-pointer">
            {t('header.colaboradores')}
          </button>
          <button onClick={() => handleNavigation('/contact')} className="hover:text-gray-300 cursor-pointer">
            {t('header.contact')}
          </button>
          <select
            className="bg-secondary text-white py-1 px-2 rounded-md"
            onChange={(e) => changeLanguage(e.target.value)}
            value={i18n.language}
          >
            <option value="es">🇪🇸 Español</option>
            <option value="en">🇬🇧 English</option>
            <option value="sv">🇸🇪 Svenska</option>
            <option value="de">🇩🇪 Deutsch</option>
          </select>
          <button onClick={() => handleNavigation('/login')} className="hover:text-gray-300 cursor-pointer">
            {t('header.login')}
          </button>
          <button onClick={() => handleNavigation('/subir-proyecto')} className="bg-white text-primary py-1 px-4 rounded-lg hover:bg-gray-100 transition cursor-pointer">
            {t('header.register')}
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;

